import { useState, useEffect, useCallback, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useMediaQuery } from 'react-responsive';
import { useAutisti } from "../hooks/useAutisti";
import { useDebounce } from "../hooks/useDebounce";
import { useAuth } from "../context/Auth";
import useEnsureLogin from "../hooks/useEnsureLogin";
import pb from "../lib/pocketbase";

export default function Autisti() {
    pb.autoCancellation(false);
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const { mutate, isError, error } = useMutation(inserisciAutista);
    const [filtro, setFiltro] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [currentAut, setCurrentAut] = useState(null);
    const [autistaInModifica, setAutistaInModifica] = useState(null);
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const user = useAuth();

    // Filtro autisti
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Gestione filtro servizi con sovrascrittura data isMobile
    const filtroAutisti = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    const { data: autisti, isLoading: isAutistiLoading,
        isError: isAutistiError, error: autistiError,
        refresh: refreshAutisti
    } = useAutisti({ user, filter: filtroAutisti });

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    function handleShow() {
        setShow(!show);
        reset();
    }

    function indietroTasto() {
        setAutistaInModifica(null);
        handleShow();
    }

    function onClickModificaAutista(id) {
        setCurrentAut(id);
        setAutistaInModifica(autisti.find(autista => autista.id === id));
        handleShow();
    }

    async function modificaAutista(data) {
        await pb.collection('autista').update(currentAut, {
            ...data,
            "user": userDatabaseOn
        });
        setAutistaInModifica(null);
        refreshAutisti();
        handleShow();
    }

    async function eliminaAutista() {
        await pb.collection('autista').delete(currentAut);
        refreshAutisti();
        setCurrentAut(null);
    }

    async function sulClick(data) {
        !!autistaInModifica ? modificaAutista(data) : mutate(data);
    }

    async function inserisciAutista(data) {
        const ins = await pb.collection('autista').create({
            ...data,
            "user": userDatabaseOn
        });
        refreshAutisti();
        // setAutisti(autisti => { autisti.push(ins); return autisti });
        handleShow();
    }

    return (<>
        {!show && <>
            <div className="hidden lg:block self-stretch">
                <div className="collapse collapse-plus bg-base-700 items-center">
                    <div>
                        <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo autista </button>
                    </div>

                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                        Mostra/Nascondi filtri
                    </div>

                    <div className="collapse-content col-span-2">
                        <form className="grid grid-cols-2 gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Alias: </label>
                                <input {...formFiltro.register("alias")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Alias" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                                <input {...formFiltro.register("nome")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Nome" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                                <input {...formFiltro.register("cognome")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Cognome" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                                <input {...formFiltro.register("telefono")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Telefono" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Mail:</label>
                                <input {...formFiltro.register("mail")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Mail" />
                            </div>

                            <button type="reset" className="btn btn-outline btn-md justify-self-center col-span-2" onClick={formFiltro.reset}> Pulisci filtri </button>

                        </form>
                    </div>
                </div>
            </div>

            {isAutistiLoading && <div className="m-4 loading loading-spinner loading-md">Caricamento...</div>}
            <table className="hidden lg:table table w-screen table-compact table-zebra table-fixed mx-2">
                <thead>
                    <tr>
                        <td> Alias </td><td> Nome </td><td> Cognome </td><td> Telefono </td><td> Mail </td><td> Note </td><td> </td>
                    </tr>
                </thead>
                <tbody>
                    {autisti && autisti.map((autista) => <tr key={autista.id}>
                        <td className="break-normal"> <p> {autista.alias} </p></td>
                        <td className="break-normal"> <p> {autista.nome} </p></td>
                        <td className="break-normal"> <p> {autista.cognome} </p></td>
                        <td className="break-normal"> <p> {autista.telefono} </p></td>
                        <td className="break-normal"> <p> {autista.mail} </p></td>
                        <td className="break-normal"> <p> {autista.note} </p></td>
                        <td> <button className="btn btn-outline btn-xs" onClick={(() => onClickModificaAutista(autista.id))}> Modifica </button> <button className="btn btn-outline btn-xs hover:bg-red-700" onClick={() => { document.getElementById('modalElimina').showModal(); setCurrentAut(autista.id) }}> Elimina </button> </td>
                    </tr>)}
                </tbody>
            </table>

            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}

            {isMobile && <div className="mb-4 contents">
                <div className="my-2">
                    <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo autista </button>
                </div>

                {isAutistiLoading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                {isAutistiError && <div> Errore: {autistiError.message} </div>}
                {autisti && autisti.map((autista) =>
                    <div onClick={(() => onClickModificaAutista(autista.id))} key={autista.id} className="self-stretch border border-black lg:hidden">
                        <div className="grid grid-flow-row grid-cols-2 px-2">

                            <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" class="w-6 h-6" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"/></svg> 
                                <p className="ml-1 break-normal">{autista.alias}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" class="icon icon-tabler icons-tabler-outline icon-tabler-phone"><path stroke="none" d="M0 0h24v24H0z"/><path d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"/></svg> 
                                <p className="ml-1 break-normal">{autista.telefono}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" class="w-6 h-6" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/></svg> 
                                <p className="ml-1 break-normal">{autista.nome}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" class="w-6 h-6" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/></svg> 
                                <p className="ml-1 break-normal">{autista.cognome}</p>
                            </div>

                        </div>
                    </div>)}
            </div>}

            <dialog id="modalElimina" className="modal-middle rounded-md">
                <form method="dialog">
                    <p className="p-5"> Sei sicuro di voler eliminare l'autista?</p>
                    <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaAutista())}> Conferma </button>
                    <button className="btn btn-outline btn-xs m-3"> Annulla </button>
                </form>
            </dialog>
        </>}

        {/* Inserimento/Modifica dati PC*/}
        {/* Inserimento/Modifica dati PC*/}
        {/* Inserimento/Modifica dati PC*/}

        {show && !isMobile && <div className="NuovoAutista">
            {isError && <p> Formato mail errato </p>}

            <div className="divFormInserisci w-full max-w-xl m-auto">
                <form className="w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-4" onSubmit={handleSubmit(sulClick)}>

                    <div className="flex flex-col justify-center items-center mb-6">
                        <h1 className="mt-4 text-2xl font-semibold"> {!!autistaInModifica ? "MODIFICA I DATI DELL'AUTISTA" : "INSERISCI I DATI DELL'AUTISTA"} </h1>
                    </div>

                    <div className="divider" />

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Alias: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Alias" {...register("alias")} defaultValue={autistaInModifica?.alias ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={autistaInModifica?.cognome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={autistaInModifica?.nome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nazione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nazione" {...register("nazione")} defaultValue={autistaInModifica?.nazione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={autistaInModifica?.regione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={autistaInModifica?.provincia ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={autistaInModifica?.cap ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Città: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={autistaInModifica?.citta ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={autistaInModifica?.indirizzo ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={autistaInModifica?.telefono ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={autistaInModifica?.mail ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> PEC: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="PEC" {...register("mail_pec")} defaultValue={autistaInModifica?.mail_pec ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> P. Iva: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="P. Iva" {...register("partita_iva")} defaultValue={autistaInModifica?.partita_iva ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice fiscale: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice fiscale" {...register("codice_fiscale")} defaultValue={autistaInModifica?.codice_fiscale ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice PA/SDI: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice PA/SDI" {...register("codice_PA_SDI")} defaultValue={autistaInModifica?.codice_PA_SDI ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Numero patente: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Numero patente" {...register("numero_patente")} defaultValue={autistaInModifica?.numero_patente ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Tipo di patente: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Tipo patente" {...register("tipo_patente")} defaultValue={autistaInModifica?.tipo_patente ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Note: </label>
                        <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={autistaInModifica?.note ?? ""} />
                    </div>

                    <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                    </div>

                </form>
            </div>
        </div>}

        {/* Inserimento/Modifica dati mobile*/}
        {/* Inserimento/Modifica dati mobile*/}
        {/* Inserimento/Modifica dati mobile*/}

        {show && isMobile && <div className="NuovoAutista">
            {isError && <p> Formato mail errato </p>}

            <div className="w-screen m-auto">
                <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(sulClick)}>

                    <div className="flex flex-col justify-center items-center mb-6">
                        <h1 className="mt-4 text-2xl font-semibold"> {!!autistaInModifica ? "MODIFICA I DATI DELL'AUTISTA" : "INSERISCI I DATI DELL'AUTISTA"} </h1>
                    </div>

                    <div className="divider" />

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Alias: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Alias" {...register("alias")} defaultValue={autistaInModifica?.alias ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={autistaInModifica?.cognome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={autistaInModifica?.nome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nazione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nazione" {...register("nazione")} defaultValue={autistaInModifica?.nazione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={autistaInModifica?.regione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={autistaInModifica?.provincia ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={autistaInModifica?.cap ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Città: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={autistaInModifica?.citta ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={autistaInModifica?.indirizzo ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={autistaInModifica?.telefono ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={autistaInModifica?.mail ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> PEC: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="PEC" {...register("mail_pec")} defaultValue={autistaInModifica?.mail_pec ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> P. Iva: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="P. Iva" {...register("partita_iva")} defaultValue={autistaInModifica?.partita_iva ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice fiscale: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice fiscale" {...register("codice_fiscale")} defaultValue={autistaInModifica?.codice_fiscale ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice PA/SDI: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice PA/SDI" {...register("codice_PA_SDI")} defaultValue={autistaInModifica?.codice_PA_SDI ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Numero patente: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Numero patente" {...register("numero_patente")} defaultValue={autistaInModifica?.numero_patente ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Tipo di patente: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Tipo patente" {...register("tipo_patente")} defaultValue={autistaInModifica?.tipo_patente ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Note: </label>
                        <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={autistaInModifica?.note ?? ""} />
                    </div>

                    <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                    </div>

                </form>
            </div>
        </div>}
    </>
    );
}