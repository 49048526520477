import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "./context/Auth";
import { useState, useRef, useEffect } from "react";
import { useClickAway } from "react-use";
import Hamburger from 'hamburger-react'
import useEnsureLogin from "./hooks/useEnsureLogin";
import pb from "./lib/pocketbase";

export default function Home() {
    const user = useAuth();
    const element = document.querySelector(`div`);
    const [isOpen, setOpen] = useState(false)
    const ref = useRef(null);

    useClickAway(ref, () => setOpen(false));

    element.addEventListener('touchstart', (e) => {
        //e.preventDefault()
    })

    //useEnsureLogin();

    function logout() {
        pb.authStore.clear();
    }

    return user && (
        <>
            <nav className="navbar sticky top-0 bg-base-300 bg-black text-white z-50" role="navigation">
                <div className="navbar-start">
                    <div ref={ref} className="lg:hidden">
                        <Hamburger toggled={isOpen} toggle={setOpen} size={25} />
                        {isOpen && <div>
                            {/* <div className="blur-sm h-full w-full"/> */}
                            {/* <div className="absolute h-screen w-screen bg-white text-black font-bold"> */}
                            <ul className="menu menu-vertical px-1">
                                <li><CustomLink id="h-bar-menu"  to="/"><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" /></svg> Servizi </CustomLink></li>
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/ServiziFilterMobile"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="icon icon-tabler icons-tabler-outline icon-tabler-zoom"><path stroke="none" d="M0 0h24v24H0z"/><path d="M3 10a7 7 0 1 0 14 0 7 7 0 1 0-14 0M21 21l-6-6"/></svg>Cerca Servizi </CustomLink></li> : ""}
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/Mezzi"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-car"><path stroke="none" d="M0 0h24v24H0z" /><path d="M5 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0" /><path d="M5 17H3v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0H9m-6-6h15m-6 0V6" /></svg>Mezzi </CustomLink></li> : ""}
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/Autisti"><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" /></svg>Autisti </CustomLink></li> : ""}
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/Clienti"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"><path stroke="none" d="M0 0h24v24H0z" /><path d="M10 9a2 2 0 1 0 4 0 2 2 0 0 0-4 0M4 8V6a2 2 0 0 1 2-2h2M4 16v2a2 2 0 0 0 2 2h2M16 4h2a2 2 0 0 1 2 2v2M16 20h2a2 2 0 0 0 2-2v-2M8 16a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2" /></svg>Clienti </CustomLink></li> : ""}
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/Referenti"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="icon icon-tabler icons-tabler-outline icon-tabler-user-hexagon"><path stroke="none" d="M0 0h24v24H0z"/><path d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM6.201 18.744A4 4 0 0 1 10 16h4a4 4 0 0 1 3.798 2.741"/><path d="M19.875 6.27c.7.398 1.13 1.143 1.125 1.948v7.284c0 .809-.443 1.555-1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1-2.184 0l-6.75-4.27A2.225 2.225 0 0 1 3 15.502V8.217c0-.809.443-1.554 1.158-1.947l6.75-3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"/></svg>Referenti </CustomLink></li> : ""}
                                {/* <CustomLink id="h-bar-menu"  to="/Tariffe"> Tariffe </CustomLink> */}
                                {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu"  to="/Utenti"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="icon icon-tabler icons-tabler-outline icon-tabler-user"><path stroke="none" d="M0 0h24v24H0z"/><path d="M8 7a4 4 0 1 0 8 0 4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>Utenti </CustomLink></li> : ""}
                            </ul>
                            {/* </div> */}
                        </div>}
                    </div>

                    <ul>
                        {user && <li className="m-4"> Benvenuto {user.azienda}! </li>}
                    </ul>
                </div>

                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                        <li><CustomLink id="h-bar-menu" to="/"> Servizi </CustomLink></li>
                        {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Mezzi"> Mezzi </CustomLink></li> : ""}
                        {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Autisti"> Autisti </CustomLink></li> : ""}
                        {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Clienti"> Clienti </CustomLink></li> : ""}
                        {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Referenti"> Referenti </CustomLink></li> : ""}
                        {/* user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Tariffe"> Tariffe </CustomLink></li> : "" */}
                        {user.tag === "Admin" || user.tag === "SuperAdmin" ? <li><CustomLink id="h-bar-menu" to="/Utenti"> Utenti </CustomLink></li> : ""}
                    </ul>
                </div>

                <div className="navbar-end">
                    <ul>
                        {/* <button className="btn bg-gray-300 hover:bg-red-700 hidden lg:flex" onClick={logout}> Logout </button>
                        <button className="btn bg-gray-300 lg:hidden" onClick={logout}> Nuovo </button> */}

                        {/* da cambiare, bottoni diversi nella stessa posizione (valutare se come ux va bene) */}

                        <button className="btn bg-gray-300 hover:bg-red-700" onClick={logout}> Logout </button>
                    </ul>
                </div>
            </nav>
            <div className="flex flex-col items-center text-center">
                <Outlet />
            </div>
        </>
    );
}

function CustomLink({ to, children, ...props }) {
    return (
        <>
            <NavLink
                to={to} {...props}
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }
            >
                {children}
            </NavLink>
        </>
    )
}