import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Servizi from "./pages/Servizi";
import ServiziFilterMobile from "./pages/ServiziFilterMobile";
import Mezzi from "./pages/Mezzi";
import Autisti from "./pages/Autisti";
import Clienti from "./pages/Clienti";
import Tariffe from "./pages/Tariffe";
import Utenti from "./pages/Utenti";
import Referente from "./pages/Referente";
import { useAuth } from "./context/Auth";

export default function App() {
  const user = useAuth();
  return (
    <>

      <Routes>
        {!user
          ? <Route path="/login" element={<Login />} />
          : <Route path="/" element={<Home />}>
            <Route index element={<Servizi />} />
            <Route path="/ServiziFilterMobile" element={<ServiziFilterMobile />} />
            <Route path="/Mezzi" element={<Mezzi />} />
            <Route path="/Autisti" element={<Autisti />} />
            <Route path="/Clienti" element={<Clienti />} />
            <Route path="/Referenti" element={<Referente />} />
            {/* <Route path="/Tariffe" element={<Tariffe />} /> */}
            <Route path="/Utenti" element={<Utenti />} />
          </Route>}
      </Routes>

    </>
  );
}