import { useMemo } from "react";
import { useCollection } from "./useCollection";

export const useMezzi = ({ user, filter }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filterFmt = useMemo(() => {
        let tmp = `user="${userDatabaseOn}"`;

        return tmp;
    }, [filter, userDatabaseOn]);

    return useCollection({ collection: 'mezzo', filter: filterFmt, sort: 'macchina' });
}