import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import pb from "../lib/pocketbase";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export default function Auth({ children }) {
    const [user, setUser] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(user);
        const sub = pb.authStore.onChange((token, model) => {
            if (!model) navigate("/login");
            setUser(model);
        }, true);
        return sub;
    }, [])

    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    );
}