import { useState, useEffect, useMemo, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMediaQuery } from 'react-responsive';
import { useAuth } from "../context/Auth";
import { useServizi } from "../hooks/useServizi";
import { useClienti } from "../hooks/useClienti";
import { useAutisti } from "../hooks/useAutisti";
import { useMezzi } from "../hooks/useMezzi";
import { useReferenti } from "../hooks/useReferenti";
import { useDebounce } from "../hooks/useDebounce";
import dateFormat from 'dateformat';
import moment from "moment/min/moment-with-locales";
import pb from "../lib/pocketbase";

export default function ServiziFilterMobile() {
    pb.autoCancellation(false);

    // Stato form inserimento & modifica servizio
    const { register, handleSubmit, reset } = useForm();
    const [showForm, setShowForm] = useState(false);

    // Stato modifica servizio
    const [currentSer, setCurrentSer] = useState(null);
    const [servizioInModifica, setServizioInModifica] = useState(null);

    const [tipoServizio, setTipoServizio] = useState("");
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

    // Utente loggato
    const user = useAuth();

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    // Filtro servizi
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Controllo per svg se data fine < data inizio
    const startDate = new Date(formFiltro.watch("partenza_da"));
    const endDate = new Date(formFiltro.watch("partenza_a"));

    // Inutile? se si controllare le altre classi
    const filtroServizi = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    // Array di valori per i select preriempiti validi
    const tipoSer = ["Transfer", "Disposizione"];
    const tipoMez = ["Luxury sedan", "Normal sedan", "Luxury van", "Mini bus", "Green sedan"];
    const oreDisp = ["2", "4", "6", "8", "10", "24"];
    const modPag = ["Carta di credito", "Carta di credito aziendale", "Carta di credito da remoto", "Contanti", "Conto", "Fattura", "PayPal"];
    const selIVA = ["10", "Seleziona l'IVA"];
    const statFat = ["Da fatturare", "Fatturato"];
    const statSer = ["Prenotato", "In corso", "Concluso"];

    //TODO: aggiungere uno useMemo per far notare l'errore tra la data di inizio e fine nei filtri

    // Dati query
    const { data: servizi, isLoading: isServiziLoading,
        isError: isServiziError, error: serviziError,
        refresh: refreshServizi
    } = useServizi({ user, filter: filtroServizi, isMobile });

    // Servizio corrente per condividi
    const targetServizio = servizi ? servizi.find(element => element.id === currentSer) : null

    const { data: clienti, isLoading: isClientiLoading,
        isError: isClientiError, error: clientiError,
        refresh: refreshClienti
    } = useClienti({ user });

    const { data: autisti, isLoading: isAutistiLoading,
        isError: isAutistiError, error: autistiError,
        refresh: refreshAutisti
    } = useAutisti({ user });

    const { data: mezzi, isLoading: isMezziLoading,
        isError: isMezziError, error: mezziError,
        refresh: refreshMezzi
    } = useMezzi({ user });

    const { data: referenti, isLoading: isReferentiLoading,
        isError: isReferentiError, error: referentiError,
        refresh: refreshReferenti
    } = useReferenti({ user });

    const onClickModificaServizio = useCallback((id) => {
        const servizioAppoggio = servizi.find(servizio => servizio.id === id);
        setCurrentSer(servizioAppoggio?.id);
        setServizioInModifica(servizioAppoggio);
        setShowForm(true);

        reset();
    }, [servizi]);

    const handleTipoServizioChange = (e) => {
        setTipoServizio(e.target.value);
    };

    function indietroTasto() {
        setServizioInModifica(null);
        setShowForm(false);
    }

    function formatDateTimeForPocketbase(dateTime) { //funziona tutto solo da cambiare perche si :3
        const dateObj = new Date(dateTime);

        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');

        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getSeconds()).padStart(2, '0');

        const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

        return formattedDateTime;
    }

    // TODO: forse può diventare useMemo o useEffect?
    function scriviImportoIvato(e) {
        var impB = document.getElementById("importoBase").value.replace(",", ".");
        var impBparsato = parseFloat(impB);
        var impEsatto = impBparsato * 1.1;
        var impC = document.getElementById("importoCalcolato");
        impC.value = impEsatto.toFixed(2).toString();
    }

    // TODO: forse può diventare useMemo o useEffect?
    function scriviImporto(e) {
        var impB = document.getElementById("importoCalcolato").value.replace(",", ".");
        var impBparsato = parseFloat(impB);
        var impEsatto = impBparsato / 1.1;
        var impC = document.getElementById("importoBase");
        impC.value = impEsatto.toFixed(2).toString();
    }

    async function modificaServizio(data) {
        if (data.tipo_servizio != "Disposizione") data.ore_disposizione = ""; // Nulla
        if (data.partenza) data.partenza = formatDateTimeForPocketbase(data.partenza);
        console.log("MODIFICA", data);
        await pb.collection('servizio').update(currentSer, {
            ...data,
            "user": userDatabaseOn
        });
        setServizioInModifica(null);
        refreshServizi();
        setShowForm(!showForm);
    }

    async function eliminaServizio() {
        await pb.collection('servizio').delete(currentSer);
        refreshServizi();
        setCurrentSer(null);
        if (isMobile) indietroTasto();
    }

    return (<>
        {!showForm && isMobile && <>
            <div className="collapse collapse-plus bg-base-700">

                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                    Mostra/Nascondi filtri
                </div>

                <div className="collapse-content col-span-2">
                    <form className="flex flex-col gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Tipo di servizio: </label>
                            <select defaultValue="" {...formFiltro.register("tipo_servizio")} className="md:w-2/3 select select-bordered select-xs">
                                <option label="Seleziona il tipo di servizio" value="" />
                                {tipoSer.map(value => (
                                    <option key={value} value={value} label={value} />
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Cliente: </label>
                            <select defaultValue="" {...formFiltro.register("cliente")} className="md:w-2/3 select select-bordered select-xs" >
                                <option value="" label="Seleziona il cliente" />
                                {clienti && clienti.map(cliente => (
                                    <option key={cliente.id} value={cliente.id}>
                                        {cliente.ragione_sociale_alias}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Data inizio: </label>
                            <input {...formFiltro.register("partenza_da")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Data" />
                        </div>

                        <div className="md:flex md:items-center">
                            {endDate < startDate ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#FF0000" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" /></svg> : ""}
                            <label className="md:w-1/3 text-right mr-2"> Data fine:</label>
                            <input {...formFiltro.register("partenza_a")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Data" />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Partenza:</label>
                            <input {...formFiltro.register("luogo_partenza")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Partenza" />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Destinazione:</label>
                            <input {...formFiltro.register("luogo_arrivo")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Destinazione" />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Passeggero:</label>
                            <input {...formFiltro.register("passeggero")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Passeggero" />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Modalità di pagamento:</label>
                            <select defaultValue="" {...formFiltro.register("modalita_pagamento")} className="md:w-2/3 select select-bordered select-xs">
                                <option value="" label="Seleziona il metodo di pagamento" />
                                {modPag.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Autista:</label>
                            <select defaultValue="" {...formFiltro.register("autista")} className="md:w-2/3 select select-bordered select-xs" >
                                <option value="" label="Seleziona l'autista" />
                                {autisti && autisti.map(autista => (
                                    <option key={autista.id} value={autista.id}>
                                        {autista.alias}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Mezzo: </label>
                            <select defaultValue="" {...formFiltro.register("mezzo")} className="md:w-2/3 select select-bordered select-xs" >
                                <option value="" label="Seleziona il mezzo" />
                                {mezzi && mezzi.map(mezzo => (
                                    <option key={mezzo.id} value={mezzo.id}>
                                        {mezzo.macchina}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="reset" className="btn btn-outline btn-md justify-self-center col-span-2" onClick={() => formFiltro.reset({
                            tipo_servizio: "",
                            cliente: "",
                            partenza_da: "",
                            partenza_a: "",
                            luogo_partenza: "",
                            luogo_arrivo: "",
                            passeggero: "",
                            modalita_pagamento: "",
                            autista: null,
                            mezzo: "",
                        })}> Pulisci filtri </button>

                    </form>
                </div>
            </div>

            {isMobile && <div className="mb-4 contents">
                {isServiziLoading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                {isServiziError && <div> Errore: {serviziError.message} </div>}
                {servizi && servizi.map((servizio) =>
                    <div onClick={(() => onClickModificaServizio(servizio.id))} key={servizio.id} className="self-stretch border border-black lg:hidden">
                        <div className="grid grid-flow-row grid-cols-2 px-2">

                            <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" /></svg>
                                <p className="ml-1 break-normal"> {servizio.partenza ? dateFormat(servizio.partenza, 'dd/mm/yyyy HH:MM', "Z") : ""} </p>
                            </div>

                            <div></div>
                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" /></svg>
                                <p className="ml-1 break-normal">{servizio.luogo_partenza}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" /></svg>
                                <p className="ml-1 break-normal">{servizio.luogo_arrivo}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"><path stroke="none" d="M0 0h24v24H0z" /><path d="M10 9a2 2 0 1 0 4 0 2 2 0 0 0-4 0M4 8V6a2 2 0 0 1 2-2h2M4 16v2a2 2 0 0 0 2 2h2M16 4h2a2 2 0 0 1 2 2v2M16 20h2a2 2 0 0 0 2-2v-2M8 16a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2" /></svg>
                                <p className="ml-1 break-normal">{userDatabaseOn && servizio.expand.cliente && servizio.expand.cliente.ragione_sociale_alias}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" /></svg>
                                <p className="ml-1 break-normal">{servizio.numero_passeggeri}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" /></svg>
                                <p className="ml-1 break-normal">{servizio.expand.autista && servizio.expand.autista.alias}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-car"><path stroke="none" d="M0 0h24v24H0z" /><path d="M5 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0" /><path d="M5 17H3v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0H9m-6-6h15m-6 0V6" /></svg>
                                <p className="ml-1 break-normal">{servizio.expand.mezzo && servizio.expand.mezzo.macchina}</p>
                            </div>
                        </div>
                    </div>)}
            </div>}
        </>}

        {/* Modifica servizio mobile */}
        {/* Modifica servizio mobile */}
        {/* Modifica servizio mobile */}

        {showForm && isMobile && <>
            <div className="w-screen m-auto">
                <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(modificaServizio)}>

                    <div className="flex flex-col justify-center items-center">
                        <h1 className="mt-4 text-3xl font-semibold"> MODIFICA I DATI DEL SERVIZIO </h1>
                    </div>

                    <div className="divider" />

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2">Stato servizio:</label>
                        <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("stato_servizio")} onChange={(e) => { if (e.target.value === "Concluso") document.getElementById("dataFineAuto").value = moment().locale("en-ca").format("L"); }} defaultValue={servizioInModifica?.stato_servizio ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} >
                            <option label="Seleziona lo stato del servizio" value="" />
                            {statSer.map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>

                    {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2">Cliente:</label>
                        <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("cliente")} defaultValue={servizioInModifica?.cliente ?? ""} >
                            <option label="Seleziona il cliente" value="" />
                            {clienti.map(cliente => (
                                <option key={cliente.id} value={cliente.id}>
                                    {cliente.ragione_sociale_alias}
                                </option>
                            ))}
                        </select>
                    </div>}

                    <div className="border-2 border-solid rounded-md border-black self-stretch p-4">

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2">Tipo di servizio:</label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("tipo_servizio")} defaultValue={servizioInModifica?.tipo_servizio ?? ""} onChange={handleTipoServizioChange} disabled={user?.tag === 'Dipendente'} >
                                <option label="Seleziona il tipo di servizio" value="" />
                                {tipoSer.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center  mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Tipo di mezzo: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("tipo_mezzo")} defaultValue={servizioInModifica?.tipo_mezzo ?? ""} disabled={user?.tag === 'Dipendente'} >
                                <option label="Seleziona il tipo di mezzo" value="" />
                                {tipoMez.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Ore disposizione: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("ore_disposizione")} defaultValue={servizioInModifica?.ore_disposizione ?? ""} disabled={tipoServizio !== "Disposizione" || user?.tag === 'Dipendente'} >
                                <option label="Seleziona le ore a disposizione" value="" />
                                {oreDisp.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Referente: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("referente")} defaultValue={servizioInModifica?.referente ?? ""} disabled={user?.tag === 'Dipendente'} >
                                <option label="Seleziona il referente" value="" />
                                {referenti.map(referente => (
                                    <option key={referente.id} value={referente.id}>
                                        {referente.nome}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Data: </label>
                            <input required className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="datetime-local" placeholder="Data" {...register("partenza")} defaultValue={servizioInModifica?.partenza ? new Date(servizioInModifica.partenza).toISOString().slice(0, 16) : ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Partenza: </label>
                            <input required className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Partenza" {...register("luogo_partenza")} defaultValue={servizioInModifica?.luogo_partenza ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Destinazione: </label>
                            <input required className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Destinazione" {...register("luogo_arrivo")} defaultValue={servizioInModifica?.luogo_arrivo ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                    </div>

                    {/* forse si può mettere flex flex-col a tutte le label che wrappano tutto e poi marcare meglio l'etichetta per distinguerla dall'input */}
                    {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6 justify-center">
                        <label className="flex flex-col text-black font-extrabold">
                            Descrizione
                            <textarea className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4" type="text" placeholder="Descrizione" {...register("descrizione_viaggio")} defaultValue={servizioInModifica?.descrizione_viaggio ?? ""} />
                        </label>
                    </div>}

                    <div className="p-4">

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Passeggero: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Passeggero" {...register("passeggero")} defaultValue={servizioInModifica?.passeggero ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Numero passeggeri: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min="0" step="any" inputMode="decimal" pattern="[0-9]*" placeholder="Numero passeggeri" {...register("numero_passeggeri")} defaultValue={servizioInModifica?.numero_passeggeri ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'}/>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Telefono passeggero: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono passeggero" {...register("telefono_passeggero")} defaultValue={servizioInModifica?.telefono_passeggero ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Numero volo: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Numero volo" {...register("numero_volo")} defaultValue={servizioInModifica?.numero_volo ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Autista: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("autista")} defaultValue={servizioInModifica?.expand.autista.id ?? ""} disabled={user?.tag === 'Dipendente'} >
                                <option label="Seleziona l'autista" value="" />
                                {autisti && autisti.map(autista => (
                                    <option key={autista.id} value={autista.id}>
                                        {autista.alias}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Mezzo: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("mezzo")} defaultValue={servizioInModifica?.mezzo ?? ""} disabled={user?.tag === 'Dipendente'} >
                                <option label="Seleziona il mezzo" value="" />
                                {mezzi.map(mezzo => (
                                    <option key={mezzo.id} value={mezzo.id}>
                                        {mezzo.macchina}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Autista occasionale: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Autista occasionale" {...register("autista_occasionale")} defaultValue={servizioInModifica?.autista_occasionale ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Km iniziali servizio: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" step="any" inputMode="decimal" pattern="[0-9]*" placeholder="Km iniziali servizio" {...register("km_iniziali_servizio")} defaultValue={servizioInModifica?.km_iniziali_servizio ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Km finali servizio: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" step="any" inputMode="decimal" pattern="[0-9]*" placeholder="Km finali servizio" {...register("km_finali_servizio")} defaultValue={servizioInModifica?.km_finali_servizio ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} />
                        </div>

                    </div>

                    <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Preventivo: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Preventivo" {...register("preventivo")} defaultValue={servizioInModifica?.preventivo ?? ""} disabled={user?.tag === 'Dipendente'} />
                        </div>}

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Modalità di pagamento: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("modalita_pagamento")} defaultValue={servizioInModifica?.modalita_pagamento ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} >
                                <option label="Seleziona la modalità di pagamento" value="" />
                                {modPag.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Importo: </label>
                            <input id="importoBase" className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min="0" step="any" inputMode="decimal" pattern="[0-9]*" {...register("importo", { onBlur: (e) => { scriviImportoIvato(e.target.value) } })} placeholder="Importo" defaultValue={servizioInModifica?.importo ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'}/>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Importo ivato: </label>
                            <input id="importoCalcolato" className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min="0" step="any" inputMode="decimal" pattern="[0-9]*" {...register("importo_ivato", { onBlur: (e) => { scriviImporto(e) } })} placeholder="Importo ivato" defaultValue={servizioInModifica?.importo_ivato ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'}/>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> IVA: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("iva")} defaultValue={servizioInModifica?.iva ?? selIVA[0]} disabled={user?.tag === 'Dipendente'} >
                                {selIVA.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Spese: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Spese" {...register("spese")} defaultValue={servizioInModifica?.spese ?? ""} />
                        </div>}

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Prezzo coll.: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min="0" step="any" inputMode="decimal" pattern="[0-9]*" placeholder="Prezzo collaboratore" {...register("prezzo_collaboratore")} defaultValue={servizioInModifica?.prezzo_collaboratore ?? ""} />
                        </div>}

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Prezzo coll. ivato: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min="0" step="any" inputMode="decimal" pattern="[0-9]*" placeholder="Prezzo collaboratore ivato" {...register("prezzo_collaboratore_iva")} defaultValue={servizioInModifica?.prezzo_collaboratore_iva ?? ""} />
                        </div>}

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Sconto: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Sconto" {...register("sconto")} defaultValue={servizioInModifica?.sconto ?? ""} />
                        </div>}

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Dare: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Dare" {...register("dare")} defaultValue={servizioInModifica?.dare ?? ""} />
                        </div>}

                        {user.tag != "Dipendente" && <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Avere: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Avere" {...register("avere")} defaultValue={servizioInModifica?.avere ?? ""} />
                        </div>}

                    </div>

                    <div className="md:flex md:items-center mb-6 justify-center">
                        <label className="flex flex-col text-black font-extrabold">
                            Note servizio
                            <textarea className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4" type="text" placeholder="Note" {...register("note_servizio")} defaultValue={servizioInModifica?.note_servizio ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'}/>
                        </label>
                    </div>

                    <div className="border-2 border-solid rounded-md border-black self-stretch p-4">

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Data fine: </label>
                            <input id="dataFineAuto" className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="datetime-local" placeholder="Data fine" {...register("data_fine")} defaultValue={servizioInModifica?.data_fine ? new Date(servizioInModifica.data_fine).toISOString().split('T')[0] : ""} disabled={tipoServizio !== "Disposizione" || (servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente')} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Chilometri: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Chilometri" {...register("chilometri")} defaultValue={servizioInModifica?.chilometri ?? ""} disabled={tipoServizio !== "Disposizione" || (servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente')} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Ore: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Ore" {...register("ore")} defaultValue={servizioInModifica?.ore ?? ""} disabled={tipoServizio !== "Disposizione" || (servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente')} />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Ore di attesa: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Ore di attesa" {...register("ore_attesa")} defaultValue={servizioInModifica?.ore_attesa ?? ""} disabled={tipoServizio !== "Disposizione" || (servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente')} />
                        </div>

                    </div>

                    {user.tag != "Dipendente" && <div className="md:flex md:items-center mb-6 justify-center">
                        <label className="flex flex-col text-black font-extrabold">
                            Note report
                            <textarea className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4" type="text" placeholder="Note" {...register("note_report")} defaultValue={servizioInModifica?.note_report ?? ""} />
                        </label>
                    </div>}

                    {user.tag != "Dipendente" && <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Descrizione fattura: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Descrizione fattura" {...register("descrizione_fattura")} defaultValue={servizioInModifica?.descrizione_fattura ?? ""} />
                        </div>

                        <div className="md:flex md:items-center">
                            <label className="md:w-1/3 text-right mr-2"> Stato fattura: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("stato_fattura")} defaultValue={servizioInModifica?.stato_fattura ?? ""} >
                                <option label="Seleziona lo stato della fattura" value="" />
                                {statFat.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>}

                    {user.tag != "Dipendente" ?
                        <div className="flex flex-row-reverse sticky bottom-8 m-8 justify-center">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1 btn-error" onClick={(e) => { e.preventDefault(); document.getElementById('modalElimina').showModal() }}> Elimina </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={(e) => { e.preventDefault(); indietroTasto() }}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={(e) => { e.preventDefault(); document.getElementById('modalCondividi').showModal() }}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" /></svg> </button>
                        </div> :
                        <div className="flex flex-row-reverse sticky bottom-8 m-8 justify-end">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Chiudi </button>
                        </div>}
                </form>
            </div>
        </>}

        <dialog id="modalElimina" className="modal-middle rounded-md">
            <form method="dialog">
                <p className="p-5"> Sei sicuro di voler eliminare il servizio?</p>
                <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaServizio())}> Conferma </button>
                <button className="btn btn-outline btn-xs m-3"> Annulla </button>
            </form>
        </dialog>

        <dialog id="modalCondividi" className="modal-middle rounded-md">
            <form method="dialog">
                <textarea className="textarea textarea-bordered textarea-md h-screen w-screen min-h-80 max-h-80 max-w-screen-lg m-4" type="text" readOnly value={
                    targetServizio ?
                        (dateFormat(targetServizio.partenza, 'dd/mm/yyyy', "Z") + "\n" +
                        "Ore: " + dateFormat(targetServizio.partenza, 'HH:MM', "Z") + "\n" +
                            "Partenza: " + targetServizio.luogo_partenza + "\n" +
                            "Destinazione: " + targetServizio.luogo_arrivo + "\n" +
                            "Passeggero: " + targetServizio.passeggero + "\n" +
                            "N. Pax: " + targetServizio.numero_passeggeri + "\n" +
                            "N. Volo: " + targetServizio.numero_volo + "\n" +
                            targetServizio.modalita_pagamento + ": €" +
                            (["Contanti", "Paypal", "Conto"].includes(targetServizio.modalita_pagamento) ?
                                targetServizio.importo :
                                (targetServizio.modalita_pagamento === "Fattura" ?
                                    targetServizio.importo + " + IVA" :
                                    targetServizio.importo_ivato)) + "\n" +
                            (targetServizio.modalita_pagamento === "Contanti" ?
                                "Carta di credito: €" + targetServizio.importo_ivato + "\n" :
                                "") +
                            "Avere: €" + targetServizio.avere)
                        : "Caricamento..."
                } />
                <button className="btn btn-outline btn-xs m-3"> Chiudi </button>
            </form>
        </dialog>

    </>);
}