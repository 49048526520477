import { useEffect, useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useMediaQuery } from 'react-responsive';
import { useDebounce } from "../hooks/useDebounce";
import { useAuth } from "../context/Auth";
import pb from "../lib/pocketbase";

export default function Utenti() {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const { mutate, isError, error } = useMutation(inserisciUtente);
    const [utenti, setUtenti] = useState([]);
    const [filtro, setFiltro] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [showSelect, setShowSelect] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [messErrore, setMessErrore] = useState([]);
    const [clienti, setClienti] = useState([]);
    const [autisti, setAutisti] = useState([]);
    const options = [
        { value: '', key: 'Seleziona un tipo', text: 'Seleziona un tipo' },
        { value: 'Admin', key: 'Admin', text: 'Admin' },
        { value: 'Dipendente', key: 'Dipendente', text: 'Dipendente' },
        { value: 'Cliente', key: 'Cliente', text: 'Cliente' },
    ];
    const [selected, setSelected] = useState(options[0].value);
    const messaggiErrore = ["L'email inserita non è valida o è già in uso.", "Le password non corrispondono.", "Campo obbligatorio.", "Lunghezza del campo non valida.", "L'indirizzo mail inserito non è valido."];
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const user = useAuth();

    // Filtro Utenti
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Gestione filtro servizi con sovrascrittura data isMobile
    const filtroUtenti = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    useEffect(() => {
        if (userDatabaseOn !== "") {
            caricaClienti();
            caricaAutisti();
            mostraUtenti();
        }
    }, [userDatabaseOn])

    useEffect(() => {
        if (!(error === null)) {
            setMessErrore(Object.entries(error.response.data).map((key) => settaErrore(key)));
        }
    }, [error])

    const handleChange = (event) => {
        setSelected(event.target.value);
        setShowSelect(event.target.value);
    };

    async function caricaClienti() {
        const records = await pb.collection('cliente').getFullList({
            filter: `user="${userDatabaseOn}"`
        });

        setClienti(records);
    }

    async function caricaAutisti() {
        const records = await pb.collection('autista').getFullList({
            filter: `user="${userDatabaseOn}"`
        });

        setAutisti(records);
    }

    function settaErrore(key) {
        var appoggio = "Errore";
        switch (key[1].code) {
            case "validation_invalid_email":
                appoggio = messaggiErrore[0];
                break;
            case "validation_values_mismatch":
                appoggio = messaggiErrore[1];
                break;
            case "validation_required":
                appoggio = messaggiErrore[2] + "(" + key[0] + ")";
                break;
            case "validation_length_out_of_range":
                appoggio = messaggiErrore[3] + "(" + key[0] + ")";
                break;
            case "validation_is_email":
                appoggio = messaggiErrore[4];
                break;
        }
        return appoggio;
    }

    function handleShow() {
        setShow(!show);
        reset();
    }

    function indietroTasto() {
        handleShow();
    }

    const mostraUtenti = async () => {
        if (!user) return;
        const records = await pb.collection('user').getFullList({
            sort: 'azienda',
            filter: `creato_da="${userDatabaseOn}"`
        });

        setUtenti(records);
    }

    async function mostraUtentiFiltrati() {
        const records = await pb.collection('user').getFullList(200, {
            filter: `${filtro}~"${messaggio}"`
        });

        setUtenti(records);
    }

    async function eliminaUtente() {
        await pb.collection('user').delete(currentUser);
        mostraUtenti();
        setCurrentUser(null);
    }

    async function sulClick(data) {
        mutate(data);
    }

    async function inserisciUtente(data) {
        data.orarioApertura = "";
        data.orarioChiusura = "";
        if (selected === "Dipendente") data.collegatoCliente = "";
        if (selected === "Cliente") {
            data.collegatoDipendente = "";
            data.orarioApertura = "2022-01-01 08:00:00.123Z";
            data.orarioChiusura = "2022-01-01 18:00:00.123Z";
        }
        const ins = await pb.collection('user').create({
            ...data,
            "creato_da": user.tag === "SuperAdmin" ? user.id : user.creato_da
        });
        mostraUtenti();
        // setUtenti(utenti => { utenti.push(ins); return utenti });
        handleShow();
    }

    return (
        <>
            {!show && <div className="flex flex-col items-center justify-center">
                <div className="space-y-4 w-full lg:flex justify-center items-center p-4">
                    {/* <div className="space-y-4 w-full hidden lg:flex justify-center items-center p-4"> */}
                    <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo utente </button>
                    <label> Cerca: </label><input className="input input-bordered input-xs w-full max-w-xs" type="text" placeholder="Cerca..." value={messaggio} onChange={(e) => { setMessaggio(e.target.value) }} />
                    <label> Filtro: </label><select className="select select-bordered select-xs w-full max-w-xs" value={filtro} onChange={(e) => { setFiltro(e.target.value); }}>
                        <option key="0" value="note">
                            Seleziona il filtro
                        </option>
                        <option key="1" value="azienda">
                            Azienda
                        </option>
                        <option key="2" value="email">
                            Mail
                        </option>
                        <option key="3" value="tag">
                            Tipo
                        </option>
                    </select>
                    <button className="btn btn-outline btn-xs ml-2" onClick={mostraUtentiFiltrati}> Vai </button>
                </div>
                <table className="lg:table table w-screen table-compact table-zebra table-fixed mx-2">
                    {/* <table className="hidden lg:table table w-screen table-compact table-zebra table-fixed"> */}
                    <thead>
                        <tr>
                            <td> Azienda </td><td> Mail </td><td> Tipo </td><td> </td>
                        </tr>
                    </thead>
                    <tbody>
                        {utenti.map((utente) => <tr key={utente.id}>
                            <td className="break-normal"> <p> {utente.azienda} </p></td>
                            <td className="break-normal"> <p> {utente.email} </p></td>
                            <td className="break-normal"> <p> {utente.tag} </p></td>
                            <td> <button className="btn btn-outline btn-xs hover:bg-red-700" onClick={() => { document.getElementById('modalElimina').showModal(); setCurrentUser(utente.id) }}> Elimina </button> </td>
                        </tr>)}
                    </tbody>
                </table>
                <dialog id="modalElimina" className="modal-middle rounded-md">
                    <form method="dialog">
                        <p className="p-5"> Sei sicuro di voler eliminare l'utente?</p>
                        <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaUtente())}> Conferma </button>
                        <button className="btn btn-outline btn-xs m-3"> Annulla </button>
                    </form>
                </dialog>
            </div>}

            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}

            {/* {isMobile && <div className="mb-4 contents">
                <div className="my-2">
                    <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo utente </button>
                </div>

                {isUtentiloading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                {isUtentiError && <div> Errore: {utentiError.message} </div>}
                {utenti && utenti.map((utenti) =>
                    <div onClick={(() => onClickModificaUtente(utenti.id))} key={utenti.id} className="self-stretch border border-black lg:hidden">
                        <p> {utenti.partenza ? dateFormat(utenti.partenza, 'dd/mm/yyyy HH:MM', "Z") : ""} </p>
                        <p> {utenti.luogo_partenza} </p>
                        <p> {utenti.luogo_arrivo} </p>
                        <p> {userDatabaseOn && utenti.expand.cliente && utenti.expand.cliente.ragione_sociale_alias} </p>
                        <p> {utenti.numero_passeggeri} </p>
                        <p> {utenti.expand.autista && utenti.expand.autista.alias} </p>
                        <p> {utenti.expand.mezzo && utenti.expand.mezzo.macchina} </p>
                    </div>)}
            </div>} */}

            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}

            {show && !isMobile && <div className="NuovoUtente">


                {isError && <div> {messErrore.map((key) => <p> {key} </p>)} </div>}

                <div className="divFormInserisci w-full max-w-xl m-auto">
                    <form className="w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> INSERISCI I DATI DELL'UTENTE </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Nome" {...register("azienda")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3 text-right mr-2">
                                <label> Tipo di account: </label>
                            </div>
                            <div onChange={handleChange} className="md:w-2/3">
                                <select value={selected} className="select select-bordered select-xs w-full max-w-xl" {...register("tag")}>
                                    {options.map(option => (
                                        <option key={option.key} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {showSelect === "Dipendente" &&
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Dipendente da collegare: </label>
                                <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required={showSelect === "Dipendente"} {...register("collegatoDipendente")}>
                                    <option key="0" value="">
                                        Seleziona l'autista
                                    </option>
                                    {autisti.map(autista => (
                                        <option key={autista.id} value={autista.id}>
                                            {autista.alias}
                                        </option>
                                    ))}
                                </select>
                            </div>}

                        {showSelect === "Cliente" &&
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Cliente da collegare: </label>
                                <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required={showSelect === "Cliente"} {...register("collegatoCliente")}>
                                    <option key="0" value="">
                                        Seleziona il cliente
                                    </option>
                                    {clienti.map(cliente => (
                                        <option key={cliente.id} value={cliente.id}>
                                            {cliente.ragione_sociale_alias}
                                        </option>
                                    ))}
                                </select>
                            </div>}

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("email")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Password: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="password" placeholder="Password" {...register("password")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Conferma password: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="password" placeholder="Conferma Password" {...register("passwordConfirm")} />
                        </div>

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
            </div>}

            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}

            {show && isMobile && <div className="NuovoUtente">


                {isError && <div> {messErrore.map((key) => <p> {key} </p>)} </div>}

                <div className="w-screen m-auto">
                    <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> INSERISCI I DATI DELL'UTENTE </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Nome" {...register("azienda")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3 text-right mr-2">
                                <label> Tipo di account: </label>
                            </div>
                            <div onChange={handleChange} className="md:w-2/3">
                                <select value={selected} className="select select-bordered select-xs w-full max-w-xl" {...register("tag")}>
                                    {options.map(option => (
                                        <option key={option.key} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {showSelect === "Dipendente" &&
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Dipendente da collegare: </label>
                                <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required={showSelect === "Dipendente"} {...register("collegatoDipendente")}>
                                    <option key="0" value="">
                                        Seleziona l'autista
                                    </option>
                                    {autisti.map(autista => (
                                        <option key={autista.id} value={autista.id}>
                                            {autista.alias}
                                        </option>
                                    ))}
                                </select>
                            </div>}

                        {showSelect === "Cliente" &&
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Cliente da collegare: </label>
                                <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required={showSelect === "Cliente"} {...register("collegatoCliente")}>
                                    <option key="0" value="">
                                        Seleziona il cliente
                                    </option>
                                    {clienti.map(cliente => (
                                        <option key={cliente.id} value={cliente.id}>
                                            {cliente.ragione_sociale_alias}
                                        </option>
                                    ))}
                                </select>
                            </div>}

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("email")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Password: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="password" placeholder="Password" {...register("password")} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Conferma password: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="password" placeholder="Conferma Password" {...register("passwordConfirm")} />
                        </div>

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
            </div>}
        </>
    );
}