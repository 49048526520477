import { useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMediaQuery } from 'react-responsive';
import { useMezzi } from "../hooks/useMezzi";
import { useAuth } from "../context/Auth";
import { useDebounce } from "../hooks/useDebounce";
import useEnsureLogin from "../hooks/useEnsureLogin";
import pb from "../lib/pocketbase";
import dateFormat from 'dateformat';

export default function Mezzi() {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const [filtro, setFiltro] = useState("");
    const [key, setKey] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [showModalZTL, setShowModalZTL] = useState(false);
    const [currentMez, setCurrentMez] = useState(null);
    /**
     * Se null non sono in modifica
     */
    const [mezzoInModifica, setMezzoInModifica] = useState(null);
    const categ = ["Berlina Standard", "Berlina Luxury", "Van Luxury", "Bus Luxury", "Elettrica Luxury"];
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const user = useAuth();

    // Filtro mezzi
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Gestione filtro servizi con sovrascrittura data isMobile
    const filtroMezzi = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    const { data: mezzi, isLoading: isMezziLoading,
        isError: isMezziError, error: mezziError,
        refresh: refreshMezzi
    } = useMezzi({ user, filter: filtroMezzi });

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    function handleShow() {
        setShow(!show);
        reset();
    }

    function indietroTasto() {
        setMezzoInModifica(null);
        handleShow();
    }

    function onClickModificaMezzo(id) {
        setCurrentMez(id);
        setMezzoInModifica(mezzi.find(mezzo => mezzo.id === id));
        handleShow();
    }

    async function modificaMezzo(data) {
        await pb.collection('mezzo').update(currentMez, {
            ...data,
            "user": userDatabaseOn
        });
        setMezzoInModifica(null);
        refreshMezzi();
        handleShow();
    }

    function mostraModalZTL() {
        setShowModalZTL(true);
    }

    async function eliminaMezzo() {
        await pb.collection('mezzo').delete(currentMez);
        refreshMezzi();
        setCurrentMez(null);
    }

    async function sulClick(data) {
        console.log(data)
        !!mezzoInModifica ? modificaMezzo(data) : inserisciMezzo(data);
    }

    async function inserisciMezzo(data) {
        await pb.collection('mezzo').create({
            ...data,
            "user": userDatabaseOn
        });
        refreshMezzi();
        handleShow();
    }

    function callback(e) {
        setFiltro(e.target.value);
        setKey(e.target.selectedIndex);
    }

    return (
        <>
            {/* visualizzazione desktop */}
            {/* visualizzazione desktop */}
            {/* visualizzazione desktop */}

            {!show && <>
                <div className="hidden lg:block self-stretch">
                    <div className="collapse collapse-plus bg-base-700 items-center">
                        <div>
                            <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo mezzo </button>
                        </div>

                        <input type="checkbox" />
                        <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                            Mostra/Nascondi filtri
                        </div>

                        <div className="collapse-content col-span-2">
                            <form className="grid grid-cols-2 gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Auto: </label>
                                    <input {...formFiltro.register("macchina")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Auto" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Targa: </label>
                                    <input {...formFiltro.register("targa")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Targa" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Colore: </label>
                                    <input {...formFiltro.register("colore")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Colore" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Immatricolazione: </label>
                                    <input {...formFiltro.register("data_immatricolazione")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Immatricolazione" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Bollo:</label>
                                    <input {...formFiltro.register("scadenza_bollo")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Bollo" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Assicurazione:</label>
                                    <input {...formFiltro.register("scadenza_assicurazione")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Assicurazione" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Revisione:</label>
                                    <input {...formFiltro.register("scadenza_revisione")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Revisione" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Tagliando:</label>
                                    <input {...formFiltro.register("scadenza_tagliando")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Tagliando" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Autorizzazione:</label>
                                    <input {...formFiltro.register("scadenza_autorizzazione_comunale")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Autorizzazione" />
                                </div>
                                <button type="reset" className="btn btn-outline btn-md justify-self-center col-span-2" onClick={formFiltro.reset}> Pulisci filtri </button>

                            </form>
                        </div>
                    </div>
                </div>
                {isMezziLoading && <div className="m-4 loading loading-spinner loading-md">Caricamento...</div>}
                <table className="hidden lg:table table w-screen table-compact table-zebra table-fixed mx-2">
                    <thead>
                        <tr>
                            <td> Auto </td><td> Targa </td><td> Colore </td><td> Immatricolazione </td><td> Bollo </td><td> Assicurazione </td><td> Revisione </td><td> Tagliando </td><td> Autorizzazione </td><td> Note </td><td> </td>
                        </tr>
                    </thead>
                    <tbody>
                        {mezzi && mezzi.map((mezzo) => <tr key={mezzo.id}>
                            <td className="break-normal"> <p> {mezzo.macchina} </p></td>
                            <td className="break-normal"> <p> {mezzo.targa} </p></td>
                            <td className="break-normal"> <p> {mezzo.colore} </p></td>
                            <td className="break-normal"> <p> {mezzo.data_immatricolazione ? dateFormat(mezzo.data_immatricolazione, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.scadenza_bollo ? dateFormat(mezzo.scadenza_bollo, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.scadenza_assicurazione ? dateFormat(mezzo.scadenza_assicurazione, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.scadenza_revisione ? dateFormat(mezzo.scadenza_revisione, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.scadenza_tagliando ? dateFormat(mezzo.scadenza_tagliando, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.scadenza_autorizzazione_comunale ? dateFormat(mezzo.scadenza_autorizzazione_comunale, 'dd/mm/yyyy') : ""} </p></td>
                            <td className="break-normal"> <p> {mezzo.note} </p></td>
                            <td className="break-normal"> <button className="btn btn-outline btn-xs" onClick={(() => onClickModificaMezzo(mezzo.id))}> Modifica </button> <button className="btn btn-outline btn-xs hover:bg-red-700" onClick={() => { document.getElementById('modalElimina').showModal(); setCurrentMez(mezzo.id) }}> Elimina </button> </td>
                        </tr>)}
                    </tbody>
                </table>

                {/* visualizzazione mobile */}
                {/* visualizzazione mobile */}
                {/* visualizzazione mobile */}

                {isMobile && <div className="mb-4 contents">
                    <div className="my-2">
                        <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo mezzo </button>
                    </div>

                    {isMezziLoading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                    {isMezziError && <div> Errore: {mezziError.message} </div>}
                    {mezzi && mezzi.map((mezzo) =>
                        <div onClick={(() => onClickModificaMezzo(mezzo.id))} key={mezzo.id} className="self-stretch border border-black lg:hidden">
                            <div className="grid grid-flow-row grid-cols-2 px-2">

                                <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" class="icon icon-tabler icons-tabler-outline icon-tabler-car"><path stroke="none" d="M0 0h24v24H0z"/><path d="M5 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0"/><path d="M5 17H3v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0H9m-6-6h15m-6 0V6"/></svg>
                                    <p className="ml-1 break-normal">{mezzo.macchina}</p>
                                </div>

                                <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icons-tabler-outline icon-tabler-badge-cc"><path stroke="none" d="M0 0h24v24H0z"/><path d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><path d="M10 10.5a1.5 1.5 0 0 0-3 0v3a1.5 1.5 0 0 0 3 0M17 10.5a1.5 1.5 0 0 0-3 0v3a1.5 1.5 0 0 0 3 0"/></svg>
                                    <p className="ml-1 break-normal">{mezzo.targa}</p>
                                </div>

                                <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" class="icon icon-tabler icons-tabler-outline icon-tabler-category"><path stroke="none" d="M0 0h24v24H0z"/><path d="M4 4h6v6H4zM14 4h6v6h-6zM4 14h6v6H4zM14 17a3 3 0 1 0 6 0 3 3 0 1 0-6 0"/></svg>
                                    <p className="ml-1 break-normal">{mezzo.categoria}</p>
                                </div>

                                <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" class="w-6 h-6" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"/></svg>
                                    <p className="ml-1 break-normal">{mezzo.posti}</p>
                                </div>
                            </div>
                        </div>)}
                </div>}

                <dialog id="modalElimina" className="modal-middle rounded-md">
                    <form method="dialog">
                        <p className="p-5"> Sei sicuro di voler eliminare il mezzo?</p>
                        <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaMezzo())}> Conferma </button>
                        <button className="btn btn-outline btn-xs m-3"> Annulla </button>
                    </form>
                </dialog>
            </>}

            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}

            {show && !isMobile && <div className="NuovoMezzo background">

                <div className="divFormInserisci w-full max-w-xl m-auto">
                    <form className="w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> {!!mezzoInModifica ? "MODIFICA I DATI DEL MEZZO" : "INSERISCI I DATI DEL MEZZO"} </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Auto: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Auto" {...register("macchina")} defaultValue={mezzoInModifica?.macchina ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Targa: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Targa" {...register("targa")} defaultValue={mezzoInModifica?.targa ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Colore: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Colore" {...register("colore")} defaultValue={mezzoInModifica?.colore ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Categoria: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("categoria")} defaultValue={mezzoInModifica?.categoria ?? ""} >
                                <option label="Seleziona un mezzo" value="" />
                                {categ.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> N. posti: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min={0} placeholder="Posti" {...register("posti")} defaultValue={mezzoInModifica?.posti ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Immatricolazione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("data_immatricolazione")} defaultValue={mezzoInModifica?.data_immatricolazione ? new Date(mezzoInModifica.data_immatricolazione).toISOString().split('T')[0] : ""} />
                        </div>

                        <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                            <label className="flex flex-col text-black font-extrabold mb-6">Scadenze</label>
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Bollo: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_bollo")} defaultValue={mezzoInModifica?.scadenza_bollo ? new Date(mezzoInModifica.scadenza_bollo).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Assicurazione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_assicurazione")} defaultValue={mezzoInModifica?.scadenza_assicurazione ? new Date(mezzoInModifica.scadenza_assicurazione).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Revisione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_revisione")} defaultValue={mezzoInModifica?.scadenza_revisione ? new Date(mezzoInModifica.scadenza_revisione).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Tagliando: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_tagliando")} defaultValue={mezzoInModifica?.scadenza_tagliando ? new Date(mezzoInModifica.scadenza_tagliando).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Autorizzazione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_autorizzazione_comunale")} defaultValue={mezzoInModifica?.scadenza_autorizzazione_comunale ? new Date(mezzoInModifica.scadenza_autorizzazione_comunale).toISOString().split('T')[0] : ""} />
                            </div>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Comune autorizzazione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Comune autorizzazione" {...register("comune_autorizzazione")} defaultValue={mezzoInModifica?.comune_autorizzazione ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nr. telepass: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Numero telepass" {...register("numero_telepass")} defaultValue={mezzoInModifica?.numero_telepass ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Licenza: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Licenza" {...register("licenza")} defaultValue={mezzoInModifica?.licenza ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Note: </label>
                            <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={mezzoInModifica?.note ?? ""} />
                        </div>

                        {/* <label> ZTL: </label><input type="text" placeholder="ZTL" {...register("scadenza_ztl")} defaultValue={mezzoInModifica?.scadenza_ztl ?? ""} /> */}
                        {/* <label> ZTL: </label><button onClick={mostraModalZTL()}> Aggiungi </button> */}

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
                {/* {showModalZTL && <div className="ModalBoxZTL">
                    <form onSubmit={handleSubmit(sulClick)}>
                        <label> Comune: </label><input type="text" placeholder="Comune" {...register("comune")} defaultValue={!!mezzoInModifica.scadenza_bollo ?? ""} />
                        <label> Scadenza ZTL: </label><input type="date" placeholder="Scadenza ZTL" {...register("scadenza")} defaultValue={!!mezzoInModifica.scadenza_bollo ?? ""} />
                        <button type="submit"> Salva </button>
                        <button onClick={(() => setShowModalZTL(false))}> Annulla </button>
                    </form>
                </div>} */}
            </div>}

            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}

            {show && isMobile && <div className="NuovoMezzo background">

                <div className="w-screen m-auto">
                    <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> {!!mezzoInModifica ? "MODIFICA I DATI DEL MEZZO" : "INSERISCI I DATI DEL MEZZO"} </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Auto: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Auto" {...register("macchina")} defaultValue={mezzoInModifica?.macchina ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Targa: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Targa" {...register("targa")} defaultValue={mezzoInModifica?.targa ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Colore: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Colore" {...register("colore")} defaultValue={mezzoInModifica?.colore ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Categoria: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("categoria")} defaultValue={mezzoInModifica?.categoria ?? ""} >
                                <option label="Seleziona un mezzo" value="" />
                                {categ.map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> N. posti: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="number" min={0} placeholder="Posti" {...register("posti")} defaultValue={mezzoInModifica?.posti ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Immatricolazione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("data_immatricolazione")} defaultValue={mezzoInModifica?.data_immatricolazione ? new Date(mezzoInModifica.data_immatricolazione).toISOString().split('T')[0] : ""} />
                        </div>

                        <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                            <label className="flex flex-col text-black font-extrabold mb-6">Scadenze</label>
                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Bollo: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_bollo")} defaultValue={mezzoInModifica?.scadenza_bollo ? new Date(mezzoInModifica.scadenza_bollo).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Assicurazione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_assicurazione")} defaultValue={mezzoInModifica?.scadenza_assicurazione ? new Date(mezzoInModifica.scadenza_assicurazione).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Revisione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_revisione")} defaultValue={mezzoInModifica?.scadenza_revisione ? new Date(mezzoInModifica.scadenza_revisione).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Tagliando: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_tagliando")} defaultValue={mezzoInModifica?.scadenza_tagliando ? new Date(mezzoInModifica.scadenza_tagliando).toISOString().split('T')[0] : ""} />
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Autorizzazione: </label>
                                <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="date" {...register("scadenza_autorizzazione_comunale")} defaultValue={mezzoInModifica?.scadenza_autorizzazione_comunale ? new Date(mezzoInModifica.scadenza_autorizzazione_comunale).toISOString().split('T')[0] : ""} />
                            </div>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Comune autorizzazione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Comune autorizzazione" {...register("comune_autorizzazione")} defaultValue={mezzoInModifica?.comune_autorizzazione ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nr. telepass: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Numero telepass" {...register("numero_telepass")} defaultValue={mezzoInModifica?.numero_telepass ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Licenza: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Licenza" {...register("licenza")} defaultValue={mezzoInModifica?.licenza ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Note: </label>
                            <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={mezzoInModifica?.note ?? ""} />
                        </div>

                        {/* <label> ZTL: </label><input type="text" placeholder="ZTL" {...register("scadenza_ztl")} defaultValue={mezzoInModifica?.scadenza_ztl ?? ""} /> */}
                        {/* <label> ZTL: </label><button onClick={mostraModalZTL()}> Aggiungi </button> */}

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
                {/* {showModalZTL && <div className="ModalBoxZTL">
                    <form onSubmit={handleSubmit(sulClick)}>
                        <label> Comune: </label><input type="text" placeholder="Comune" {...register("comune")} defaultValue={!!mezzoInModifica.scadenza_bollo ?? ""} />
                        <label> Scadenza ZTL: </label><input type="date" placeholder="Scadenza ZTL" {...register("scadenza")} defaultValue={!!mezzoInModifica.scadenza_bollo ?? ""} />
                        <button type="submit"> Salva </button>
                        <button onClick={(() => setShowModalZTL(false))}> Annulla </button>
                    </form>
                </div>} */}
            </div>}

        </>
    );
}