import { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment/min/moment-with-locales";
import dateFormat from 'dateformat';
import pb from "../lib/pocketbase";
import { useCollection } from "./useCollection";

export const useServizi = ({ user, filter, isMobile }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filterFmt = useMemo(() => {
        let filtri = [
            `user="${userDatabaseOn}"`,
        ];

        if (user.tag === "Dipendente") {
            filtri.push(`autista="${user.collegatoDipendente}"`);
        }
        if (user.tag === "Cliente") {
            filtri.push(`cliente="${user.collegatoCliente}"`);
        }

        if (Object.values(filter).some(st => !(st == "" || st == undefined || st == null))) {
            const containsFilter = [
                "user",
                "tipo_servizio",
                "luogo_partenza",
                "luogo_arrivo",
                "modalita_pagamento",
                "passeggero",
                "mezzo",
                "cliente",
                "autista",
            ];

            filtri = [
                ...containsFilter
                    .filter(val => filter[val] != undefined && filter[val] != "")
                    .map(val => `${val} ~ "${filter[val]}"`),
                ...filtri
            ];

            if (filter.partenza_da && filter.partenza_da != undefined && filter.partenza_da != "")
                filtri.push(`partenza >= "${moment(filter.partenza_da).startOf("day").toISOString()}"`);
            if (filter.partenza_a && filter.partenza_a != undefined && filter.partenza_a != "")
                filtri.push(`partenza <= "${moment(filter.partenza_a).endOf("day").toISOString()}"`);
            if (isMobile && !filter)
                filtri.push('stato_servizio!="Concluso"');
        } else {
            filtri.push('stato_servizio!="Concluso"');
        }

        let tmp = filtri.join(" && ");

        console.log("useServizi filter", filter, tmp);

        return tmp;
    }, [filter, userDatabaseOn]);

    // Se l'utente è un dipendente mostra solo i suoi servizi
    if (user.tag === "Dipendente")
        filter.autista = user.collegatoDipendente;

    // Se l'utente è un cliente mostra solo i suoi servizi
    if (user.tag === "Cliente")
        filter.cliente = user.collegatoCliente;

    return useCollection({
        collection: 'servizio',
        filter: filterFmt,
        sort: 'partenza',
        expand: ["cliente", "autista", "mezzo"].join(","),
    });
}