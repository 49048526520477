import { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment/min/moment-with-locales";
import pb from "../lib/pocketbase";

export const useCollection = ({ collection, filter, sort, expand }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [requestKey, setRequestKey] = useState(Math.random());
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(null);
        setRequestKey(Math.random());

        pb.collection(collection).getFullList({
            filter,
            sort,
            expand,
            $cancelKey: requestKey
        })
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));

        return () => {
            pb.cancelRequest(requestKey)
        };
    }, [collection, filter, sort, refresh]);

    const abortCallback = useCallback(() => pb.cancelRequest(requestKey), [requestKey]);

    return { data, isLoading: loading, error, isError: error !== null, abort: abortCallback, refresh: () => setRefresh(prev => !prev) };
};

// ESEMPIO DI UTILIZZO. Già messa su file esterno. Cancellare (chiedere a Dami)?
export const useServizi = ({ user, isMobile, date, filtro }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filter = useMemo(() => {
        let tmp = `user="${userDatabaseOn}" && stato_servizio!="Concluso"`;

        if (filtro) {
            tmp = `user="${userDatabaseOn}" && ${filtro}`;
        }

        if (user.tag === "Dipendente") {
            tmp += ` && autista="${user.collegatoDipendente}"`;
        }
        if (user.tag === "Cliente") {
            tmp += ` && cliente="${user.collegatoCliente}"`;
        }

        if (isMobile) {
            tmp += ` && partenza>="${moment(date).startOf("day").toISOString()}" && partenza<="${moment(date).endOf("day").toISOString()}"`;
        }

        return tmp;
    }, [user, isMobile, date, filtro]);

    return useCollection({ collection: 'servizio', filter, sort: 'partenza' });
}

// Già messa su file esterno. Cancellare (chiedere a Dami)?
export const useClienti = ({ user }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filter = useMemo(() => {
        let tmp = `user="${userDatabaseOn}"`;

        return tmp;
    }, [user]);

    return useCollection({ collection: 'cliente', filter, sort: 'ragione_sociale_alias' });
}

// Già messa su file esterno. Cancellare (chiedere a Dami)?
export const useAutisti = ({ user }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filter = useMemo(() => {
        let tmp = `user="${userDatabaseOn}"`;

        return tmp;
    }, [user]);

    return useCollection({ collection: 'autista', filter, sort: 'alias' });
}

// Già messa su file esterno. Cancellare (chiedere a Dami)?
export const useMezzi = ({ user }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filter = useMemo(() => {
        let tmp = `user="${userDatabaseOn}"`;

        return tmp;
    }, [user]);

    return useCollection({ collection: 'mezzo', filter, sort: 'macchina' });
}

// Già messa su file esterno. Cancellare (chiedere a Dami)?
export const useReferenti = ({ user }) => {
    const userDatabaseOn = user.tag === "SuperAdmin" ? user.id : user.creato_da;

    let filter = useMemo(() => {
        let tmp = `user="${userDatabaseOn}"`;

        return tmp;
    }, [user]);

    return useCollection({ collection: 'referente', filter, sort: 'nome' });
}